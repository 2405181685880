.leaflet-tooltip {
	padding: 10px !important;
	box-sizing: border-box !important;
	border: none !important;
	background-color: transparent !important;
	font-size: 15px !important;
	font-weight: 600 !important;
	font-family: Arial, Helvetica, sans-serif !important;
	box-shadow: none !important;
	text-shadow: 0px 0px 0px 2px black !important;
	&:before {
		display: none !important;
	}
}
.marker-tooltip {
	@apply bg-red-500;
}
