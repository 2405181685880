@tailwind components;

@layer components {
	.popup-tooltip {
		background: black !important;
		width: 300px;

		&:before,
		&:after {
			border-bottom-color: black !important;
		}

		&.show {
			opacity: 1 !important;
		}
	}
}
