.top-nav {
	.upper {
		font-size: 14px;
		@apply h-14 flex px-3 py-0.5 items-center;
		.logo {
			background-image: url(../../../public/images/logo2.png);
			@apply w-32 h-full min-h-full m-0 bg-no-repeat bg-contain bg-center block;
		}
		.top-nav-btn {
			@apply px-2 py-1 border border-theme-500 text-white text-sm select-none my-2 mx-0.5;
		}
	}
	.lower {
		@apply bg-theme-700;
	}
	@apply absolute z-[2000]  top-0 left-0 right-0 flex flex-col bg-theme-800;
}
