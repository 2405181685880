.map-list {
	@apply flex flex-col  py-2 space-y-2;
}

.map-group {
	@apply bg-black/5 rounded-sm transition-colors;

	&.open {
		@apply bg-black/10;
	}

	.map-group-title {
		@apply text-center relative text-white select-none cursor-pointer py-2;

		.map-group-toggler {
			@apply absolute top-[50%] right-3 -translate-y-[50%] transition-transform;
			&.open {
				@apply rotate-180;
			}
		}
	}
	.map-group-items {
		@apply flex flex-wrap px-3 py-3 -mx-1 -mb-2;
		.map-list-item {
			flex: 1 1 25%;
			@apply ml-1 mr-1 mb-2 bg-black/20 hover:bg-black/10;
		}
	}
}

.map-list-item {
	@apply px-3 py-1.5  bg-black/5 text-center rounded-sm text-white cursor-pointer transition-colors;
	@apply hover:bg-black/10;
}
