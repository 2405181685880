.top-maps {
	@apply absolute top-0 left-0 w-full z-[1500] text-white bg-theme-800 border-b border-theme-600 px-2;

	.top-maps-items {
		@apply flex py-2 space-x-2;
		.top-maps-item {
			@apply border border-transparent flex-1 text-center transition-colors bg-black/20 px-2 py-1.5 rounded-sm select-none cursor-pointer hover:bg-black/30;
			&.active {
				@apply bg-theme-600 border-black/80;
			}
		}
	}

	.sub-items {
		@apply flex mb-2 space-x-2;
		.sub-item {
			flex-basis: 200px;
			@apply px-4 py-1 bg-black/20 text-center cursor-pointer transition-colors hover:bg-black/30 rounded-sm;
		}
	}
}
