.map-popup {
	max-width: 300px;
	@apply mb-10;

	.popup-title {
		@apply mb-2 text-lg font-semibold flex justify-between items-center;
		.bookmark-btn {
			@apply transition-colors hover:bg-lime-500 rounded-full cursor-pointer w-5 h-5 flex items-center justify-center;
			&.active {
				@apply bg-lime-500;
			}
		}
		.close-btn {
			@apply transition-colors hover:bg-red-400 rounded-full cursor-pointer w-5 h-5 flex items-center justify-center;
		}
	}
	.popup-content {
		* {
			max-width: 100%;
		}
		max-height: 150px;
		@apply mb-2 overflow-y-auto;
		&::-webkit-scrollbar {
			@apply w-1;
		}

		/* Track */
		&::-webkit-scrollbar-track {
			@apply bg-white/20 rounded-full;
		}

		/* Handle */
		&::-webkit-scrollbar-thumb {
			@apply bg-white/20 rounded-full;
		}

		/* Handle on hover */
		&::-webkit-scrollbar-thumb:hover {
			@apply bg-white/30;
		}
	}
	.popup-image {
		@apply max-w-full mb-3 cursor-pointer;
	}

	.leaflet-popup-content-wrapper {
		@apply bg-black/70 text-white rounded-md;
	}
	.leaflet-popup-content {
		@apply mx-3.5 my-3;
	}
	.leaflet-popup-tip {
		@apply bg-black/70;
	}

	.popup-btn {
		@apply w-full px-2 py-1.5 bg-green-500 transition-opacity rounded hover:opacity-80;
		&.btn-red {
			@apply bg-red-500;
		}
	}
	.popup-btn + .popup-btn {
		@apply mt-1.5;
	}
}
