.leaflet-control-container {
	.leaflet-top {
		@apply pt-24;
	}
	.leaflet-control {
		border: none !important;
	}
	.leaflet-left,
	.leaflet-right {
		@apply duration-[400ms] transition-all ease-in-out;
	}
}

.left-sidebar-open {
	.leaflet-left {
		padding-left: 400px;
	}
	&.is-mobile {
		.leaflet-left {
			padding-left: 0;
		}
	}
}
.right-sidebar-open {
	.leaflet-right {
		padding-right: 300px;
	}
}

.leaflet-top.leaflet-left {
	@apply flex flex-col;

	.leaflet-draw {
		@apply order-5;
	}
}

.leaflet-draw-section {
	@apply m-0 p-0;

	.leaflet-draw-draw-polyline,
	.leaflet-draw-draw-polygon {
		@apply w-9 h-9 bg-theme-800  flex-shrink-0 transition-colors border-none rounded text-white flex items-center justify-center;
		&.active {
			@apply bg-blue-500 hover:bg-blue-600;
		}
		&:hover {
			@apply bg-theme-600;
		}
	}
}

.leaflet-top.leaflet-right {
	@apply bottom-0 pb-5 flex flex-col;
	.leaflet-control {
		@apply flex flex-col items-end justify-between;
	}
}

.pinlink-mode,
.marker-mode {
	.leaflet-container {
		@apply cursor-crosshair;
	}
}
